import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MenuMain = makeShortcode("MenuMain");
const MenuBlock = makeShortcode("MenuBlock");
const Link = makeShortcode("Link");
const SocialLinks = makeShortcode("SocialLinks");
const LightDarkSwitchButton = makeShortcode("LightDarkSwitchButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <MenuMain mdxType="MenuMain">
  <MenuBlock mdxType="MenuBlock">
    <Link to="/" mdxType="Link">Home</Link>
    <Link to="/woke" mdxType="Link">Woke</Link>
  </MenuBlock>
  <MenuBlock mdxType="MenuBlock">
    <SocialLinks mdxType="SocialLinks" />
    <span>&nbsp;&nbsp;</span>
    <LightDarkSwitchButton darkLabel="🌙" lightLabel="☀️" mdxType="LightDarkSwitchButton" />
  </MenuBlock>
    </MenuMain>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      